import React, { useRef, useState } from 'react'
import { type MessageContextType, MessageType } from '../../types/context/MessageContextType'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CheckCircleOutline, ErrorOutline, HighlightOff, Info } from '@mui/icons-material'

export const MessageContext = React.createContext<MessageContextType>(null!)

interface Props {
    children?: React.ReactNode
}

export const MessageContextProvider = (props: Props): JSX.Element => {
    const { t } = useTranslation()
    const [openModalId, setOpenModalId] = useState<string | null>(null)
    const [type, setType] = useState<MessageType>()
    const [title, setTitle] = useState<string>()
    const [message, setMessage] = useState<string>()
    const [jsx, setJsx] = useState<React.ReactNode>()
    const [button1, setButton1] = useState<React.ReactNode>(
        <Button
            size='large'
            sx={{ margin: 'auto' }}
            onClick={() => openModal(openModalId ?? '', false)}
        >{t('common.ok')}
        </Button>
    )
    const [button2, setButton2] = useState<React.ReactNode | undefined>()

    const closeTimeOut = useRef<NodeJS.Timeout | null>(null)

    const icon = (): React.ReactNode | null => {
        switch (type) {
            case MessageType.info:
                return <Info color='info' sx={{ fontSize: '80px' }} />
            case MessageType.warning:
                return <ErrorOutline color='warning' sx={{ fontSize: '80px' }} />
            case MessageType.error:
                return <HighlightOff color='error' sx={{ fontSize: '80px' }} />
            case MessageType.correctAnswer:
                return <CheckCircleOutline color='success' sx={{ fontSize: '80px' }} />
            case MessageType.wrongAnswer:
                return <HighlightOff color='error' sx={{ fontSize: '80px' }} />
        }
        return null
    }

    const headTitle = (): string | null => {
        switch (type) {
            case MessageType.info:
                return t('info.title')
            case MessageType.warning:
                return t('warning.title')
            case MessageType.error:
                return t('error.title')
            case MessageType.correctAnswer:
                return t('test.correctAnswer')
            case MessageType.wrongAnswer:
                return t('test.wrongAnswer')
        }
        return null
    }

    /**
     * Clear interval needed in case we want immediately open modal after closing previous one
     */
    const openModal = (modalId: string, open: boolean): void => {
        if (closeTimeOut.current !== null) {
            clearTimeout(closeTimeOut.current)
        }

        if (open) {
            setOpenModalId(modalId)
        } else {
            setOpenModalId(null)
            closeTimeOut.current = setTimeout(() => {
                reset() // TODO nečakalo na animaciu tak zatial takto
            }, 500)
        }
    }

    const isModalOpen = (modalId: string): boolean => {
        return openModalId === modalId
    }

    // Reset states when modal close
    function reset (): void {
        setType(undefined)
        setTitle(undefined)
        setMessage(undefined)
        setButton2(undefined)
        setButton1(
            <Button
                size='large'
                sx={{ margin: 'auto' }}
                onClick={() => { openModal(openModalId ?? '', false); }}
            >{t('common.ok')}
            </Button>
        )
    }

    // TODO - Maybe split this into a separate context to produce less re-renders
    return (
        <MessageContext.Provider
            value={{
                message: message,
                jsx: jsx,
                type: type,
                title: title,
                headTitle: headTitle(),
                icon: icon(),
                button1: button1,
                button2: button2,
                isModalOpen: isModalOpen,
                openModal: openModal,
                setMessage: setMessage,
                setJsx: setJsx,
                setType: setType,
                setTitle: setTitle,
                setButton1: setButton1,
                setButton2: setButton2,
                reset: reset
            }}
        >
            {props.children}
        </MessageContext.Provider>
    )
}
