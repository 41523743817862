import React from 'react'

export enum MessageType {
    info,
    warning,
    error,
    correctAnswer,
    wrongAnswer
}

export interface MessageContextType {
    message: string |undefined
    jsx: React.ReactNode
    title: string | undefined
    type: MessageType | undefined
    headTitle: string | null
    icon: React.ReactNode | null
    button1: React.ReactNode
    button2: React.ReactNode | undefined
    isModalOpen(id: string): boolean
    openModal(modalId: string, open: boolean): void
    setMessage(body: string | undefined): void
    setJsx(jsx: React.ReactNode): void
    setTitle(value: string | undefined) : void
    setType(type: MessageType | undefined): void
    setButton1(button1: React.ReactNode): void
    setButton2(button2: React.ReactNode|undefined): void
    reset: () => void
}